import React from 'react';
import { useFallbackMediaQuery } from '@wix/experts-common';
import s from './ServiceColumns.module.scss';

interface ServiceColumnsProps {
  categories: Array<JSX.Element>;
  setIsPopoverOpen: (isOpen: boolean) => void;
  isMobileUserAgent?: boolean;
}

const ServiceColumns = ({
  categories,
  setIsPopoverOpen,
  isMobileUserAgent,
}: ServiceColumnsProps) => {
  const isMore1280View = useFallbackMediaQuery(
    '(min-width: 1280px)',
    !isMobileUserAgent,
  );
  return (
    <div
      id="mph-popover-inner-content"
      className={s.popoverInnerContent}
      tabIndex={0}
      role="list"
      onKeyUp={(e) => {
        if (e.key === 'Escape') {
          setIsPopoverOpen(false);
        }
      }}
    >
      <div className={s.col}>
        {categories[0]}
        {categories[1]}
      </div>
      <div className={s.divider}></div>
      <div className={s.col}>
        {categories[2]}
        {categories[3]}
      </div>
      <div className={s.divider}></div>
      <div className={s.col}>
        {categories[4]}
        {!isMore1280View && categories[5]}
      </div>
      {isMore1280View && (
        <>
          <div className={s.divider}></div>
          <div className={s.col}>{categories[5]}</div>
        </>
      )}
    </div>
  );
};

export default ServiceColumns;
