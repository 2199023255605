import React, { FC, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { Menu, Dismiss } from '@wix/wix-ui-icons-common';
import { SkeletonRectangle, Box, Divider } from '@wix/design-system';
import { useFallbackMediaQuery } from '@wix/experts-common';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useTranslation, useExperiments } from '@wix/fe-essentials-standalone';
import { useBi } from '@components/Hooks/useBi';
import CategoryContainer from './CategoryContainer';
import { CurrencySelectorProps } from './CurrencySelector/CurrencySelector';
import FormattedMarketplaceLogo from './FormattedMarketplaceLogo';
import { Category, HeaderData } from './models';
import { sortCategories } from './utils/sortCategories';
import ServiceColumns from './ServiceColumns';
import { addProtocolToPath } from './utils/addProtocolToPath';
import { dataHooks } from './utils/constants';
import DesktopMenuPopover from './DesktopMenuPopover';
import AllServicesButton from './Desktop/AllServicesButton';
import JoinLink from './Desktop/JoinLink';
import SellInMarketplaceDropdown from './Desktop/SellInMarketplaceDropdown';
import StudioTemplatesLink from './Desktop/StudioTemplatesLink';
import MobileScreens from './Mobile';
import { useTemplateGalleryExperiment } from '@components/Hooks/useTemplateGalleryExperiment';
import s from './MarketplaceHeader.module.scss';

const DynamicLoggedinArea = dynamic(
  () => import('@components/Header/LoginArea/LoggedinArea'),
  {
    loading: () => <SkeletonRectangle height={'12px'} width={'72px'} />,
    ssr: false,
  },
);

const DynamicNotLoggedinArea = dynamic(
  () => import('@components/Header/LoginArea/NotLoggedinArea'),
  {
    loading: () => <SkeletonRectangle height={'12px'} width={'72px'} />,
    ssr: false,
  },
);

export interface MarketplaceHeaderProps {
  loading?: boolean;
  onClick?: () => void;
  headerData?: HeaderData;
  language?: string;
  isInternal?: boolean;
  origin?: string;
  pageName?: string;
  currencySelectorProps?: Omit<CurrencySelectorProps, 'localeDataTranslations'>;
  isMobileUserAgent?: boolean;
}

const MarketplaceHeader: FC<MarketplaceHeaderProps> = ({
  loading,
  onClick,
  isInternal = false,
  headerData = {
    keys: {},
    services: [],
    userData: {},
    userProfileImage: '',
  },
  origin = 'https://www.wix.com',
  language = 'en',
  pageName = 'explore',
  currencySelectorProps,
  isMobileUserAgent,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments({ readOnly: true });
  const isLess1000View = useFallbackMediaQuery(
    '(max-width: 999px)',
    !!isMobileUserAgent,
  );
  const sortedCategories: Array<Category> = useMemo(
    () => sortCategories(headerData?.services),
    [headerData?.services],
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean | undefined>(
    undefined,
  );
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [popoverArrowLeft, setPopoverArrowLeft] = useState<number>(380);

  const marketplaceUrlPrefix = addProtocolToPath(`${origin}/marketplace`);
  const isUserLoggedIn = !!headerData?.userData?.email;
  const userProfileImg = headerData?.userProfileImage;

  const logger = useBi();
  const isTemplatesGalleryEnable = useTemplateGalleryExperiment();
  const enableMarketplaceTemplates = isTemplatesGalleryEnable();

  const MobileMenuOpenButton = () => {
    return (
      <li className={`${s.cell} ${s.mobile} ${s.right}`}>
        <button
          aria-label={t('marketplace-header-open-menu-label')}
          data-hook="mobile-menu"
          className={s.mobileButton}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'mobile menu',
                pageName,
              }),
            );
            setMobileMenuOpen(!mobileMenuOpen);
          }}
        >
          {mobileMenuOpen ? <Dismiss /> : <Menu />}
        </button>
      </li>
    );
  };

  const categoriesToDisplay = useMemo(() => {
    return sortedCategories.map((category: Category) => (
      <CategoryContainer
        key={category.displayKey}
        category={category}
        marketplaceUrlPrefix={marketplaceUrlPrefix}
        pageName={pageName}
      />
    ));
  }, [sortedCategories, marketplaceUrlPrefix, pageName]);

  const columns = useMemo(() => {
    if (categoriesToDisplay.length) {
      return (
        <ServiceColumns
          categories={categoriesToDisplay}
          setIsPopoverOpen={setIsPopoverOpen}
          isMobileUserAgent={isMobileUserAgent}
        />
      );
    }
    return null;
  }, [categoriesToDisplay]);

  useEffect(() => {
    if (!isLess1000View) {
      setMobileMenuOpen(false);
    } else {
      setIsPopoverOpen(false);
    }
  }, [isLess1000View]);

  useEffect(() => {
    if (isPopoverOpen) {
      document.body.style.overflow = 'hidden';
      const arrowdownPosition = document
        .querySelector(`#mph-services-link`)
        ?.getBoundingClientRect()?.right;
      setPopoverArrowLeft(
        arrowdownPosition ? arrowdownPosition - 15 : popoverArrowLeft,
      );
      (
        document?.querySelector('#mph-popover-inner-content') as HTMLElement
      )?.focus();
    } else if (isPopoverOpen === false) {
      document.body.style.overflow = '';
      (document?.querySelector('#mph-services-link') as HTMLElement)?.focus();
    }
  }, [isPopoverOpen]);

  function renderAllServicesButton() {
    return (
      <li className={`${s.cell} ${s.desktop}`}>
        <AllServicesButton
          label={headerData.keys.services}
          onClick={(e: any) => {
            e.stopPropagation();
            setIsPopoverOpen(!isPopoverOpen);
          }}
          pageName={pageName}
        />
      </li>
    );
  }

  function renderJoinLink() {
    return (
      <li className={`${s.cell} ${s.desktop}`}>
        <JoinLink
          label={headerData.keys.join}
          marketplaceUrlPrefix={marketplaceUrlPrefix}
          pageName={pageName}
        />
      </li>
    );
  }

  function renderStudioTemlatesLink() {
    return (
      <li className={`${s.cell} ${s.desktop}`}>
        <StudioTemplatesLink
          label={headerData.keys.studioTemplates}
          marketplaceUrlPrefix={marketplaceUrlPrefix}
          pageName={pageName}
        />
      </li>
    );
  }

  function renderSellInMarketplaceDropdown() {
    return (
      <li className={`${s.cell} ${s.desktop}`}>
        <SellInMarketplaceDropdown
          label={headerData.keys.sellInMarketplace}
          items={[
            {
              text: headerData.keys.offerServices,
              onClick: () => {
                logger.report(
                  marketplaceHeaderClientGenericClick({
                    clickType: 'sell in the marketplace - offer services',
                    pageName,
                  }),
                );
                window.open(headerData.keys.offerServicesUrl, '_blank');
              },
            },
            {
              text: headerData.keys.sellStudioTemplates,
              onClick: () => {
                logger.report(
                  marketplaceHeaderClientGenericClick({
                    clickType:
                      'sell in the marketplace - sell studio templates',
                    pageName,
                  }),
                );
                window.open(headerData.keys.sellStudioTemplatesUrl, '_blank');
              },
            },
          ]}
          pageName={pageName}
        />
      </li>
    );
  }

  function renderDivider() {
    return (
      <li className={`${s.cell} ${s.desktop}`}>
        <Box height="24px">
          <Divider skin="dark" direction="vertical" />
        </Box>
      </li>
    );
  }

  function renderLoginArea() {
    return (
      <li className={`${s.cell} ${s.desktop} ${s.right} ${s.last}`}>
        <DynamicLoggedinArea
          keys={headerData.keys}
          language={language}
          userProfileImg={userProfileImg}
          userData={headerData.userData}
          currencySelectorProps={currencySelectorProps}
          pageName={pageName}
          isLess1000View={isLess1000View}
          marketplaceUrlPrefix={marketplaceUrlPrefix}
        />
      </li>
    );
  }

  function renderLoading() {
    return (
      <li className={`${s.cell} ${s.desktop} ${s.right} ${s.last}`}>
        <SkeletonRectangle height={'12px'} width={'72px'} />
      </li>
    );
  }

  return (
    <header data-hook={dataHooks.marketplaceHeader} onClick={onClick}>
      <div
        id="mph"
        className={`${s.marketplaceHeader} ${mobileMenuOpen ? s.fixedTop : ''}`}
      >
        <div
          className={s.container}
          onClick={() => {
            setIsPopoverOpen(false);
          }}
        >
          <nav className={s.nav}>
            <ul className={s.navList}>
              <FormattedMarketplaceLogo
                pageName={pageName}
                marketplaceUrlPrefix={marketplaceUrlPrefix}
                isInternal={isInternal}
              />
              {renderAllServicesButton()}
              {!enableMarketplaceTemplates && renderJoinLink()}
              {enableMarketplaceTemplates && renderStudioTemlatesLink()}
              {enableMarketplaceTemplates && renderDivider()}
              {enableMarketplaceTemplates && renderSellInMarketplaceDropdown()}
              {loading && renderLoading()}
              {!isUserLoggedIn && !loading ? (
                <DynamicNotLoggedinArea
                  keys={headerData.keys}
                  language={language}
                  pageName={pageName}
                  currencySelectorProps={currencySelectorProps}
                  marketplaceUrlPrefix={marketplaceUrlPrefix}
                  isLess1000View={isLess1000View}
                />
              ) : (
                !loading && renderLoginArea()
              )}
              {<MobileMenuOpenButton />}
            </ul>
            <DesktopMenuPopover
              isInternal={isInternal}
              categories={headerData?.services}
              marketplaceUrlPrefix={marketplaceUrlPrefix}
              pageName={pageName}
              setIsPopoverOpen={setIsPopoverOpen}
              isPopoverOpen={isPopoverOpen}
              popoverArrowLeft={popoverArrowLeft}
              onClickOverlay={(e: any) => {
                if (
                  (e.target as Element).id === 'mph-popover-overlay' ||
                  (e.target as Element).id === 'mph-popover'
                ) {
                  setIsPopoverOpen(false);
                }
              }}
            />
          </nav>
        </div>
        <div
          role="list"
          data-hook="mobile-menu-container"
          className={`${s.mobileMenu} ${mobileMenuOpen ? s.menuOpen : ''}`}
        >
          <MobileScreens
            isUserLoggedIn={isUserLoggedIn}
            sortedCategories={headerData.services}
            marketplaceUrlPrefix={marketplaceUrlPrefix}
            language={language}
            headerData={headerData}
            pageName={pageName}
            currencySelectorProps={currencySelectorProps}
            isLess1000View={isLess1000View}
          />
        </div>
      </div>
    </header>
  );
};

export default MarketplaceHeader;
