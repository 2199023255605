import React, { useMemo } from 'react';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '../../Hooks/useBi';
import ServiceListItem from '../ServiceListItem';
import { Category } from '../models';
import s from './CategoryContainer.module.scss';

interface CategoryContainerProps {
  category: Category;
  marketplaceUrlPrefix: string;
  pageName: string;
}

const CategoryContainer = ({
  category,
  marketplaceUrlPrefix,
  pageName,
}: CategoryContainerProps) => {
  const logger = useBi();

  const ServiceList = useMemo(
    () =>
      category.services
        .filter((service) => !service.hidden)
        .map((service) => {
          return (
            <ServiceListItem
              key={service.displayKey}
              service={service}
              categoryPathName={category.pathName}
              marketplaceUrlPrefix={marketplaceUrlPrefix}
              pageName={pageName}
            />
          );
        }),
    [category, marketplaceUrlPrefix, pageName],
  );

  return (
    <div key={category?.displayKey} className={s.categoryContainer}>
      <div className={s.category} role="listitem">
        <a
          className={s.categoryLink}
          href={`${marketplaceUrlPrefix}/hire/${category.pathName}`}
          data-hook={`category-link-${category.displayKey}`}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'all services',
                pageName,
                click_data: category.displayKey,
              }),
            );
          }}
        >
          {category.translatedKey}
        </a>
      </div>
      {ServiceList}
    </div>
  );
};

export default CategoryContainer;
