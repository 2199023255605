import React from 'react';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import {
  LanguageOption,
  getTranslatedLanguageOptions,
} from '../utils/Language/formatLanguageOptions';
import { useLanguageSelected } from '../../Hooks/useLanguageSelected';
import { LanguageSelectorProps } from '../LanguageSelector/LanguageSelector';
import SelectionScreen from '../SelectionScreen';
import { useBi } from '../../Hooks/useBi';

interface LanguageSelectionScreenProps extends LanguageSelectorProps {
  onBack: () => void;
  title: string;
  value: string;
  pageName: string;
}

const LanguageSelectionScreen = ({
  onBack,
  title,
  value,
  pageName,
}: LanguageSelectionScreenProps) => {
  const logger = useBi();
  const onLanguageSelected = useLanguageSelected(pageName, value);
  const options = getTranslatedLanguageOptions();

  return (
    <SelectionScreen
      options={options}
      onClick={(option: LanguageOption) => {
        logger.report(
          marketplaceHeaderClientGenericClick({
            clickType: 'change language',
            pageName,
            click_data: `[${value},${option.id}]`,
          }),
        );
        onLanguageSelected(option);
      }}
      onBack={onBack}
      title={title}
      selectedOptionId={value}
    />
  );
};

export default LanguageSelectionScreen;
