import React, { FC } from 'react';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '@components/Hooks/useBi';
import { Category } from '../models';
import s from './DesktopMenuPopover.module.scss';
import sc from './CategoryContainer.module.scss';

interface CategoryContainerProps {
  isCategoriesMenuInHeaderExperimentEnabled?: boolean;
  category: Category;
  marketplaceUrlPrefix: string;
  pageName: string;
}

const CategoryContainer = ({
  category,
  marketplaceUrlPrefix,
  pageName,
}: CategoryContainerProps) => {
  const logger = useBi();
  return (
    <div key={category?.displayKey} className={sc.categoryContainer}>
      <div className={sc.category} role="listitem">
        <a
          className={sc.categoryLink}
          href={`${marketplaceUrlPrefix}/hire/${category.pathName}`}
          data-hook={`category-link-${category.displayKey}`}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'all services',
                pageName,
                click_data: category.displayKey,
              }),
            );
          }}
        >
          {category.translatedKey}
        </a>
      </div>
    </div>
  );
};

export interface DesktopMenuPopoverProps {
  isInternal?: boolean;
  marketplaceUrlPrefix: string;
  pageName: string;
  categories: Category[] | null;
  setIsPopoverOpen: (isOpen: boolean) => void;
  isPopoverOpen?: boolean;
  popoverArrowLeft?: number;
  onClickOverlay: (e: any) => void;
}
const DesktopMenuPopover: FC<DesktopMenuPopoverProps> = ({
  isInternal = false,
  marketplaceUrlPrefix,
  pageName,
  categories,
  setIsPopoverOpen,
  isPopoverOpen = false,
  popoverArrowLeft = 380,
  onClickOverlay,
}) => {
  return (
    <div
      id="mph-popover-container"
      data-hook="mph-popover-container"
      className={s.desktopMenu}
    >
      <div
        id="mph-popover-overlay"
        className={`${s.popoverOverlay} ${isPopoverOpen ? s.popoverOpen : ''}`}
        onClick={onClickOverlay}
      >
        <div id="mph-popover" data-hook="mph-popover" className={s.popover}>
          {categories ? (
            <div
              className={`${s.popoverContent} ${isInternal ? s.internal : ''}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                id="mph-popover-inner-content"
                className={s.popoverInnerContent}
                tabIndex={0}
                role="list"
                onKeyUp={(e) => {
                  if (e.key === 'Escape') {
                    setIsPopoverOpen(false);
                  }
                }}
              >
                {categories.map((category: Category) => (
                  <CategoryContainer
                    key={category.displayKey}
                    category={category}
                    marketplaceUrlPrefix={marketplaceUrlPrefix}
                    pageName={pageName}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={`${s.popoverArrow} ${isPopoverOpen ? s.popoverOpen : ''}`}
        style={{ left: `${popoverArrowLeft}px` }}
      ></div>
    </div>
  );
};

export default DesktopMenuPopover;
