import _ from 'lodash';

export function addProtocolToPath(path: string) {
  const protocolHttps = 'https://';
  const protocolHttp = 'http://';
  const pathHasProtocol =
    _.startsWith(path, protocolHttp) || _.startsWith(path, protocolHttps);
  if (!pathHasProtocol) {
    return `${protocolHttps}${path}`;
  }
  return path;
}
