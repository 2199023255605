import React, { FC } from 'react';
import {
  Accordion,
  accordionItemBuilder,
  Text,
  TextButton,
  Box,
} from '@wix/design-system';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { ChevronDown, ChevronUp } from '@wix/wix-ui-icons-common';
import { Category } from '../models';
import { useBi } from '../../Hooks/useBi';
import { dataHooks, getServiceLink } from '../utils/constants';
import s from './CategoryLinkAccordion.module.scss';

const BlackChevronUp = ({ pageName }: { pageName: string }) => {
  const logger = useBi();
  return (
    <Box paddingRight="SP4">
      <TextButton
        skin="dark"
        onClick={() => {
          logger.report(
            marketplaceHeaderClientGenericClick({
              clickType: 'click in profile drop down menu',
              click_data: 'browse services open',
              pageName,
            }),
          );
        }}
      >
        <ChevronUp />
      </TextButton>
    </Box>
  );
};

const BlackChevronDown = ({ pageName }: { pageName: string }) => {
  const logger = useBi();
  return (
    <Box paddingRight="SP4">
      <TextButton
        skin="dark"
        onClick={() => {
          logger.report(
            marketplaceHeaderClientGenericClick({
              clickType: 'click in profile drop down menu',
              click_data: 'browse services close',
              pageName,
            }),
          );
        }}
      >
        <ChevronDown />
      </TextButton>
    </Box>
  );
};

interface MarketplaceCategoryLinkProps {
  category: Category;
  marketplaceUrlPrefix: string;
  pageName: string;
}

const MarketplaceCategoryLink: FC<MarketplaceCategoryLinkProps> = ({
  category,
  marketplaceUrlPrefix,
  pageName,
}) => {
  const logger = useBi();
  const hrefLink = getServiceLink(marketplaceUrlPrefix, category.pathName);

  const dataHook = dataHooks.mobileCategoryLink(category.displayKey);

  return (
    <div key={category?.displayKey} role="listitem">
      <Box display="block" padding="SP2 0 SP2 SP8">
        <TextButton
          as="a"
          skin="dark"
          weight="thin"
          size="medium"
          href={hrefLink}
          dataHook={dataHook}
          className={s.categoryLink}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'all services',
                pageName,
                click_data: category.displayKey,
              }),
            );
          }}
        >
          <Box display="block">
            <Text size="medium" weight="normal">
              {category.translatedKey}
            </Text>
          </Box>
        </TextButton>
      </Box>
    </div>
  );
};

const CategoriesLinks = ({
  categories,
  marketplaceUrlPrefix,
  pageName,
}: CategoryAccordionProps) => {
  return (
    <Box backgroundColor="#F4F7FF" direction="vertical">
      {categories.map((cat) => {
        return (
          <MarketplaceCategoryLink
            key={cat.pathName}
            category={cat}
            marketplaceUrlPrefix={marketplaceUrlPrefix}
            pageName={pageName}
          />
        );
      })}
    </Box>
  );
};

interface CategoryLinkAccordionProps {
  accordionTitle: string;
  categories: Array<Category>;
  marketplaceUrlPrefix: string;
  pageName: string;
}

type CategoryAccordionProps = Omit<
  CategoryLinkAccordionProps,
  'accordionTitle'
>;

const CategoryLinkAccordion = ({
  accordionTitle,
  categories,
  marketplaceUrlPrefix,
  pageName,
}: CategoryLinkAccordionProps) => {
  return (
    <Box display="block" margin="0 -24px" className={s.accordionContainer}>
      <Accordion
        dataHook={dataHooks.categoryLinkAccordion}
        horizontalPadding="none"
        skin="light"
        hideShadow
        items={[
          accordionItemBuilder({
            title: (
              <Box display="block" paddingLeft="SP4">
                <Text size="medium" weight="normal">
                  {accordionTitle}
                </Text>
              </Box>
            ),
            children: (
              <CategoriesLinks
                categories={categories}
                marketplaceUrlPrefix={marketplaceUrlPrefix}
                pageName={pageName}
              />
            ),
            buttonType: 'node',
            showLabel: 'always',
            collapseLabel: <BlackChevronUp pageName={pageName} />,
            expandLabel: <BlackChevronDown pageName={pageName} />,
          }),
        ]}
      />
    </Box>
  );
};

export default CategoryLinkAccordion;
