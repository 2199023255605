import localeData from '@wix/locale-dataset-javascript';

export interface LanguageOption {
  id: string;
  label: string;
}

const unsupportedLanguages = ({ key }: { key: string }) =>
  ['hi'].indexOf(key) === -1;

const sortedOptions = (options: Array<LanguageOption>) => {
  const sorted = new Array<LanguageOption>(options.length - 1);
  const availableOptions = options;
  const middleArrayIndex = Math.round(availableOptions.length / 2);
  let counter = 0;
  let i = 0;
  while (i < availableOptions.length) {
    let index = 0;
    if (i % 2 === 0) {
      index = counter;
    } else {
      index = middleArrayIndex + counter;
      counter++;
    }
    const option = availableOptions[index];
    sorted[i] = option;
    i++;
  }
  return sorted.filter((x) => x);
};

export const getTranslatedLanguageOptions = (
  { desktpo = false }: { desktpo: boolean } = {
    desktpo: false,
  },
): Array<LanguageOption> => {
  const languageOptions = localeData
    .getAllSupportedLanguages()
    .filter(unsupportedLanguages)
    .map(({ key, displayName }) => {
      return {
        id: key,
        label: displayName,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.id));
  return desktpo ? sortedOptions(languageOptions) : languageOptions;
};

export const getLanguageLabel = (value: string): string => {
  const language = localeData
    .getAllSupportedLanguages()
    .filter(unsupportedLanguages)
    .find(({ key }) => key === value);
  return language ? language.displayName : value;
};

export const onLanguageSelected = (option: LanguageOption) => {
  window.open(getLanguageSelectedHref(option), '_self');
};

export const getLanguageSelectedHref = (option: LanguageOption) =>
  `https://users.wix.com/wix-users/auth/setLanguage?language=${
    option.id
  }&redirectTo=${encodeURI(
    `${window.location.protocol}//${
      option.id === 'en' ? 'www' : option.id
    }${'.wix.com'}${window.location.pathname}${window.location.search}`,
  )}`;
