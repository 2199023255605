import React, { FC } from 'react';
import { TextButton } from '@wix/design-system';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '../../../Hooks/useBi';

interface JoinLinkProps {
  label: string;
  marketplaceUrlPrefix: string;
  pageName: string;
}

const JoinLink: FC<JoinLinkProps> = ({
  label,
  marketplaceUrlPrefix,
  pageName,
}) => {
  const logger = useBi();

  return (
    <TextButton
      as="a"
      dataHook="join-link"
      skin="dark"
      size="medium"
      weight="normal"
      onClick={() => {
        logger.report(
          marketplaceHeaderClientGenericClick({
            clickType: 'get hired',
            pageName,
          }),
        );
      }}
      href={`${marketplaceUrlPrefix}/join`}
    >
      {label}
    </TextButton>
  );
};

export default JoinLink;
