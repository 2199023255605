import React, { FC } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { useHeaderContext } from '@components/Contexts/HeaderContext';
import { enableInternalMarketplaceSpec } from '@utils/constants';
import MarketplaceHeader from './MarketplaceHeader';

interface Props {
  onClick?: () => void;
  sessionId?: string;
  pageName?: string;
}

const Header: FC<Props> = ({ onClick, pageName }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { experiments } = useExperiments({ readOnly: true });
  const {
    userDetails,
    userProfileImage,
    services,
    localeDataTranslations,
    host,
    loading,
  } = useHeaderContext();

  const isInternal = experiments.enabled(enableInternalMarketplaceSpec);
  const headerData = {
    services,
    keys: {
      services: t('marketplace-header-link-services'),
      studioTemplates: t('marketplace-header-link-studio-templates'),
      sellInMarketplace: t('marketplace-header-link-sell-in-marketplace'),
      offerServices: t('marketplace-header-link-offer-services'),
      offerServicesUrl: t('marketplace-header-link-offer-services-url'),
      sellStudioTemplates: t('marketplace-header-link-sell-studio-templates'),
      sellStudioTemplatesUrl: t(
        'marketplace-header-link-sell-studio-templates-url',
      ),
      join: t('marketplace-header-link-join'),
      hire: t('marketplace-header-link-hire'),
      btnLogin: t('marketplace-header-btn-login'),
      btnLogout: t('marketplace-header-btn-logout'),
      myProjects: t('marketplace-header-link-my-projects'),
      contactSupport: t('marketplace-header-link-contact-support'),
      accountSettings: t('marketplace-header-link-account-settings'),
      wix: t('marketplace-header-link-wix'),
      wixSites: t('marketplace-header-link-wix-sites'),
      mobileTitle: t('marketplace-header-mobile-title'),
      mobileServiceMenuTitle: t('marketplace-header-mobile-browse-services'),
      mobileCurrencySelectionTitle: t(
        'marketplace-header-mobile-currency-selection-title',
      ),
      mobileLanguageSelectionTitle: t(
        'marketplace-header-mobile-language-selection-title',
      ),
    },
    localeDataTranslations,
    userData: userDetails,
    userProfileImage,
  };

  return (
    <MarketplaceHeader
      loading={loading}
      onClick={onClick}
      headerData={headerData}
      isInternal={isInternal}
      language={language}
      pageName={pageName}
      origin={host}
    />
  );
};

export default Header;
