import { useCallback } from 'react';
import { useExperiments } from '@wix/fe-essentials-standalone';
import {
  ENABLE_MARKETPLACE_TEMPLATES_SPEC,
  ENABLE_MARKETPLACE_TEMPLATES_SPEC_V2,
} from '@utils/constants';

export function useTemplateGalleryExperiment() {
  const { experiments } = useExperiments({ readOnly: true });

  const isTemplatesGalleryEnable = useCallback(() => {
    const enableMarketplaceTemplates = experiments.enabled(
      ENABLE_MARKETPLACE_TEMPLATES_SPEC,
    );
    const isTemplatesGalleryEnable = experiments.enabled(
      ENABLE_MARKETPLACE_TEMPLATES_SPEC_V2,
    );
    return enableMarketplaceTemplates || isTemplatesGalleryEnable;
  }, [experiments]);

  return isTemplatesGalleryEnable;
}
