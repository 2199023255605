import React, { FC } from 'react';
import { Button, ButtonProps } from '@wix/design-system';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { dataHooks, logoutUrl } from '../../utils/constants';
import { useBi } from '../../../Hooks/useBi';
import s from '../LoginArea.module.scss';

interface LogoutBtnProps {
  btnLogoutLabel: string;
  pageName: string;
  btnSkin?: ButtonProps['skin'];
  btnSize?: ButtonProps['size'];
}

const LogoutBtn: FC<LogoutBtnProps> = ({
  btnLogoutLabel,
  pageName,
  btnSkin = 'dark',
  btnSize = 'small',
}) => {
  const logger = useBi();
  return (
    <Button
      dataHook={dataHooks.logoutBtn}
      size={btnSize}
      priority="secondary"
      skin={btnSkin}
      onClick={() => {
        logger.report(
          marketplaceHeaderClientGenericClick({
            clickType: 'click in profile drop down menu',
            click_data: 'log out',
            pageName,
          }),
        );
        window.open(logoutUrl(window.location.href), '_self');
      }}
      className={btnSkin === 'dark' ? s.btnDarkSecondary : ''}
    >
      {btnLogoutLabel}
    </Button>
  );
};

export default LogoutBtn;
