import { Category } from '../models';

export const sortCategories = (cats?: Array<Category>) => {
  if (cats?.length) {
    return [cats[0], cats[2], cats[3], cats[4], cats[1], cats[5]].filter(
      (cat) => cat,
    );
  }
  return [];
};
