import { useCallback } from 'react';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import {
  LanguageOption,
  onLanguageSelected,
} from '../Header/utils/Language/formatLanguageOptions';
import { useBi } from './useBi';

export function useLanguageSelected(pageName: string, oldValue: string) {
  const logger = useBi();
  const handleLanguageSelected = useCallback(
    (option: LanguageOption) => {
      logger.report(
        marketplaceHeaderClientGenericClick({
          clickType: 'change language',
          pageName,
          click_data: `[${oldValue},${option.id}]`,
        }),
      );
      onLanguageSelected(option);
    },
    [pageName, oldValue],
  );

  return handleLanguageSelected;
}
