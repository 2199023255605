import React from 'react';
import {
  Accordion,
  accordionItemBuilder,
  Text,
  TextButton,
  Box,
} from '@wix/design-system';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { ChevronDown, ChevronUp } from '@wix/wix-ui-icons-common';
import { useBi } from '../../Hooks/useBi';
import { dataHooks } from '../utils/constants';
import s from './SellInMarketplaceLinkAccordion.module.scss';

interface SellInMarketplaceLinkAccordionProps {
  accordionTitle: string;
  pageName: string;
  onClick: () => void;
  items: { text: string; onClick: () => void }[];
}

const AccordionItem = ({
  key,
  label,
  onClick,
}: {
  key: number;
  label: string;
  onClick: () => void;
}) => (
  <div key={key} role="listitem">
    <Box display="block" padding="SP2 0 SP2 SP8">
      <TextButton
        as="a"
        skin="dark"
        size="medium"
        weight="normal"
        onClick={onClick}
      >
        {label}
      </TextButton>
    </Box>
  </div>
);

const BlackChevronUp = ({ pageName }: { pageName: string }) => {
  const logger = useBi();
  return (
    <Box paddingRight="SP4">
      <TextButton
        skin="dark"
        onClick={() => {
          logger.report(
            marketplaceHeaderClientGenericClick({
              clickType: 'click in profile drop down menu',
              click_data: 'browse services open',
              pageName,
            }),
          );
        }}
      >
        <ChevronUp />
      </TextButton>
    </Box>
  );
};

const BlackChevronDown = ({ pageName }: { pageName: string }) => {
  const logger = useBi();
  return (
    <Box paddingRight="SP4">
      <TextButton
        skin="dark"
        onClick={() => {
          logger.report(
            marketplaceHeaderClientGenericClick({
              clickType: 'click in profile drop down menu',
              click_data: 'browse services close',
              pageName,
            }),
          );
        }}
      >
        <ChevronDown />
      </TextButton>
    </Box>
  );
};

const SellInMarketplaceLinkAccordion = ({
  accordionTitle,
  items,
  pageName,
  onClick,
}: SellInMarketplaceLinkAccordionProps) => {
  return (
    <Box display="block" margin="0 -24px" className={s.accordionContainer}>
      <Accordion
        dataHook={dataHooks.sellInMarketplaceLinkAccordion}
        horizontalPadding="none"
        skin="light"
        hideShadow
        onAnimationEnter={onClick}
        items={[
          accordionItemBuilder({
            title: (
              <Box display="block" paddingLeft="SP4">
                <Text size="medium" weight="normal">
                  {accordionTitle}
                </Text>
              </Box>
            ),
            children: items?.map((item, index) => (
              <AccordionItem
                key={index}
                label={item.text}
                onClick={item.onClick}
              />
            )),
            buttonType: 'node',
            showLabel: 'always',
            collapseLabel: <BlackChevronUp pageName={pageName} />,
            expandLabel: <BlackChevronDown pageName={pageName} />,
          }),
        ]}
      />
    </Box>
  );
};

export default SellInMarketplaceLinkAccordion;
