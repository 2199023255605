import React, { FC, useState, useEffect } from 'react';
import { CurrencyOption } from '../utils/Currency/formatCurrencyOptions';
import { Category, HeaderData } from '../models';
import { CurrencySelectorProps } from '../CurrencySelector/CurrencySelector';
import CurrencySelectionScreen from '../CurrencySelectionScreen';
import LanguageSelectionScreen from '../LanguageSelectionScreen';
import MainScreen from './MainScreen';
import s from './MobileScreens.module.scss';

const ScreenMapTypes = {
  main: {},
  currency: {},
  language: {},
};
interface Props {
  isUserLoggedIn: boolean;
  sortedCategories: Array<Category>;
  marketplaceUrlPrefix: string;
  headerData?: HeaderData;
  language: string;
  pageName?: string;
  currencySelectorProps?: Omit<CurrencySelectorProps, 'localeDataTranslations'>;
  isLess1000View: boolean;
}

const MobileScreens: FC<Props> = ({
  isUserLoggedIn,
  sortedCategories,
  marketplaceUrlPrefix,
  headerData = {
    keys: {},
    services: [],
    userData: {},
    userProfileImage: '',
  },
  language = 'en',
  pageName = 'explore',
  currencySelectorProps,
  isLess1000View,
}) => {
  const shouldShowCurrencyPicker = currencySelectorProps;
  const [activeScreen, setActiveScreen] =
    useState<keyof typeof ScreenMapTypes>('main');

  useEffect(() => {
    if (!isLess1000View) {
      setActiveScreen('main');
    }
  }, [isLess1000View]);

  const onMobileMenuBack = () => {
    setActiveScreen('main');
  };

  const ScreenMap = (data: HeaderData) => ({
    main: (
      <MainScreen
        isUserLoggedIn={isUserLoggedIn}
        language={language}
        marketplaceUrlPrefix={marketplaceUrlPrefix}
        sortedCategories={sortedCategories}
        keys={headerData.keys}
        pageName={pageName}
        currencySelectorProps={currencySelectorProps}
        setActiveScreen={setActiveScreen}
      />
    ),
    currency: (
      <MainScreen
        isUserLoggedIn={isUserLoggedIn}
        language={language}
        marketplaceUrlPrefix={marketplaceUrlPrefix}
        sortedCategories={sortedCategories}
        keys={headerData.keys}
        pageName={pageName}
        currencySelectorProps={currencySelectorProps}
        setActiveScreen={setActiveScreen}
      />
    ),
    language: (
      <LanguageSelectionScreen
        title={data?.keys?.mobileLanguageSelectionTitle}
        onBack={onMobileMenuBack}
        value={language}
        pageName={pageName}
      />
    ),
  });

  return ScreenMap(headerData)[activeScreen];
};

export default MobileScreens;
