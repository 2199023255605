import React, { FC } from 'react';
import { TextButton } from '@wix/design-system';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { dataHooks } from '../../utils/constants';
import { useBi } from '../../../Hooks/useBi';

interface StudioTemplatesLinkProps {
  label: string;
  marketplaceUrlPrefix: string;
  pageName: string;
  isMobileView?: boolean;
}

const StudioTemplatesLink: FC<StudioTemplatesLinkProps> = ({
  label,
  marketplaceUrlPrefix,
  pageName,
  isMobileView,
}) => {
  const logger = useBi();

  return (
    <TextButton
      as="a"
      dataHook={dataHooks.studioTemplatesLink}
      skin="dark"
      size="medium"
      weight={isMobileView ? 'normal' : 'thin'}
      onClick={() => {
        logger.report(
          marketplaceHeaderClientGenericClick({
            clickType: 'studio templates',
            pageName,
          }),
        );
      }}
      href={`${marketplaceUrlPrefix}/templates`}
    >
      {label}
    </TextButton>
  );
};

export default StudioTemplatesLink;
