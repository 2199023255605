import React, { FC } from 'react';
import { TextButton } from '@wix/design-system';
import { ChevronDown } from '@wix/wix-ui-icons-common';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '../../../Hooks/useBi';

interface AllServicesButtonProps {
  label: string;
  onClick: (e: any) => void;
  pageName: string;
}

const AllServicesButton: FC<AllServicesButtonProps> = ({
  label,
  onClick,
  pageName,
}) => {
  const logger = useBi();
  return (
    <TextButton
      id="mph-services-link"
      as="button"
      dataHook="services-link"
      skin="dark"
      size="medium"
      weight="thin"
      onClick={(e) => {
        logger.report(
          marketplaceHeaderClientGenericClick({
            clickType: 'all services',
            pageName,
          }),
        );
        onClick(e);
      }}
      suffixIcon={<ChevronDown />}
    >
      {label}
    </TextButton>
  );
};

export default AllServicesButton;
