import { ArrowLeft } from '@wix/wix-ui-icons-common';
import React from 'react';
import { Box, Text, IconButton, TextButton } from '@wix/design-system';
import s from './SelectionScreen.module.scss';

interface Option {
  id: string;
  symbol?: any;
  label: string;
}

interface SelectionScreenProps {
  options: Array<Option>;
  onClick: (option: any) => void;
  onBack?: () => void;
  title?: string;
  selectedOptionId?: string;
}

const SelectionScreen = ({
  options,
  onClick,
  onBack,
  title,
  selectedOptionId,
}: SelectionScreenProps) => {
  return (
    <Box direction="vertical" gap="24px" className={s.selectionScreen}>
      <Box align="space-between" verticalAlign="middle">
        <Box verticalAlign="middle">
          {onBack && (
            <Box paddingRight="12px">
              <IconButton
                onClick={onBack}
                dataHook="modal-back-btn"
                skin="light"
              >
                <ArrowLeft color="black" />
              </IconButton>
            </Box>
          )}
          {title && <Text weight="bold">{title}</Text>}
        </Box>
      </Box>
      {options.map((option) => (
        <TextButton
          key={option.id}
          skin="dark"
          onClick={() => onClick(option)}
          className={option.id === selectedOptionId ? s.selectedOption : ''}
        >
          {option.label}
        </TextButton>
      ))}
    </Box>
  );
};

export default SelectionScreen;
