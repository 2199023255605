import React from 'react';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '../../Hooks/useBi';
import s from './ServiceListItem.module.scss';

const ServiceListItem = ({
  service,
  categoryPathName,
  marketplaceUrlPrefix,
  pageName,
}: {
  service: Record<string, any>;
  categoryPathName: string;
  marketplaceUrlPrefix: string;
  pageName: string;
}) => {
  const logger = useBi();

  return service.hidden ? null : (
    <div key={service?.displayKey} className={s.servicesContainer}>
      <div className={s.service} role="listitem">
        <a
          className={s.serviceLink}
          href={`${marketplaceUrlPrefix}/hire/${categoryPathName}/${service.pathName}`}
          data-hook={`service-link-${service.displayKey}`}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'all services',
                click_data: service.displayKey,
                pageName,
              }),
            );
          }}
        >
          {service.translatedKey}
        </a>
      </div>
    </div>
  );
};

export default ServiceListItem;
