import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import _ from 'lodash';
import { Category } from '@wix/ambassador-category-services/types';
import { useHttpClient, useErrorMonitor } from '@wix/fe-essentials-standalone';
import { UserDetails, LocaleDataTranslations } from '@common/types';
import { DEFAULT_CURRENCY } from '@utils/constants';

interface HeaderContextProviderProps {
  children?: ReactNode;
  services?: Array<Category>;
  userDetails?: UserDetails;
  userProfileImage?: string;
  origin?: string;
  host: string;
  entry?: string;
  localeDataTranslations?: LocaleDataTranslations;
  userCurrency?: string;
  preferredCurrency?: string;
}

type State = {
  loading?: boolean;
  services?: Array<Category>;
  userCurrency: string;
  preferredCurrency?: string;
  localeDataTranslations: LocaleDataTranslations;
  userDetails?: UserDetails;
  userProfileImage: string;
  origin?: string;
  host: string;
  entry?: string;
};

interface HeaderContextType extends State {
  dispatch: (arg: any) => void;
}

const HeaderContext = createContext<HeaderContextType | null>(null);

function mainReducer(state: State, action: any) {
  switch (action.type) {
    case ACTIONS.SET_USER_DETAILS: {
      const { userDetails } = action.payload;
      return {
        ...state,
        userDetails,
      };
    }
    case ACTIONS.SET_USER_PROFILE_IMAGE: {
      const { userProfileImage } = action.payload;
      return {
        ...state,
        userProfileImage,
      };
    }
    case ACTIONS.SET_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }
    default:
      throw new Error();
  }
}

export const ACTIONS = {
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_USER_PROFILE_IMAGE: 'SET_USER_PROFILE_IMAGE',
  SET_LOADING: 'SET_LOADING',
};

export function HeaderContextProvider(props: HeaderContextProviderProps) {
  const errorMonitor = useErrorMonitor();
  const httpClient = useHttpClient();

  const [state, dispatch] = useReducer(mainReducer, {
    loading: true,
    services: props.services,
    userDetails: props.userDetails,
    userProfileImage: props.userProfileImage || '',
    origin: props.origin,
    host: props.host,
    entry: props.entry,
    userCurrency: props?.userCurrency ?? DEFAULT_CURRENCY,
    preferredCurrency: props?.preferredCurrency,
    localeDataTranslations: props?.localeDataTranslations || {},
  });

  useEffect(() => {
    async function fetchHeaderData() {
      try {
        const {
          data: { userProfileImage, email, language },
        } = await httpClient.get<any>(`/api/user`);

        dispatch({
          type: ACTIONS.SET_USER_DETAILS,
          payload: {
            userDetails: {
              email,
              language,
            },
          },
        });

        dispatch({
          type: ACTIONS.SET_USER_PROFILE_IMAGE,
          payload: {
            userProfileImage,
          },
        });

        dispatch({
          type: ACTIONS.SET_LOADING,
          payload: {
            loading: false,
          },
        });
      } catch (e: any) {
        errorMonitor.captureException(e);
        dispatch({
          type: ACTIONS.SET_LOADING,
          payload: {
            loading: false,
          },
        });
      }
    }

    if (_.isUndefined(props.userDetails)) {
      fetchHeaderData();
    } else {
      dispatch({
        type: ACTIONS.SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  }, []);

  return (
    <HeaderContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
}

export function useHeaderContext() {
  const appContext = useContext(HeaderContext);
  if (!appContext) {
    throw new Error(
      'useHeaderContext must be used within the HeaderContext.Provider',
    );
  }
  return appContext;
}
