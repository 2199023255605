import React, { FC, useState } from 'react';
import { TextButton, PopoverMenu } from '@wix/design-system';
import { ChevronDown, ChevronUp } from '@wix/wix-ui-icons-common';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { dataHooks, popoverIds } from '../../utils/constants';
import { useBi } from '../../../Hooks/useBi';

interface SellInMarketplaceDropdownProps {
  label: string;
  items: { text: string; onClick: () => void }[];
  pageName: string;
}

const SellInMarketplaceDropdown: FC<SellInMarketplaceDropdownProps> = ({
  label,
  items,
  pageName,
}) => {
  const logger = useBi();
  const [chevronDirection, setChevronDirection] = useState<'up' | 'down'>(
    'down',
  );

  return (
    <PopoverMenu
      appendTo="parent"
      moveBy={{ y: 22 }}
      dataHook={dataHooks.sellInMarketplaceDropdown}
      placement="bottom-end"
      maxWidth={400}
      onHide={() => setChevronDirection('down')}
      onShow={() => {
        logger.report(
          marketplaceHeaderClientGenericClick({
            clickType: 'sell in the marketplace',
            pageName,
          }),
        );
        setChevronDirection('up');
      }}
      triggerElement={
        <TextButton
          as="button"
          dataHook="sell-in-marketplace-dropdown-trigger"
          skin="dark"
          size="medium"
          weight="thin"
          suffixIcon={
            chevronDirection === 'down' ? <ChevronDown /> : <ChevronUp />
          }
        >
          {label}
        </TextButton>
      }
    >
      {items?.map((item, index) => (
        <PopoverMenu.MenuItem
          key={index}
          text={item.text}
          onClick={item.onClick}
          skin="dark"
        />
      ))}
    </PopoverMenu>
  );
};

export default SellInMarketplaceDropdown;
