import React, { FC } from 'react';
import { TextButton, Box } from '@wix/design-system';
import { ChevronRight, Languages } from '@wix/wix-ui-icons-common';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { CurrencySelectorProps } from '../CurrencySelector/CurrencySelector';
import { getLanguageLabel } from '../utils/Language/formatLanguageOptions';
import { contactSupportUrl, wixUrl, dataHooks } from '../utils/constants';
import { useBi } from '@components/Hooks/useBi';
import { Category } from '../models';
import CategoryLinkAccordion from '../CategoryLinkAccordion';
import SellInMarketplaceLinkAccordion from '../SellInMarketplaceLinkAccordion';
import LoginBtn from '@components/Header/LoginArea/LoginBtn';
import LogoutBtn from '@components/Header/LoginArea/LogoutBtn';
import MyProjectsLink from '../Desktop/MyProjectsLink';
import StudioTemplatesLink from '../Desktop/StudioTemplatesLink';
import { useTemplateGalleryExperiment } from '@components/Hooks/useTemplateGalleryExperiment';
import s from './MobileScreens.module.scss';

interface Props {
  language: string;
  isUserLoggedIn: boolean;
  sortedCategories: Array<Category>;
  marketplaceUrlPrefix: string;
  pageName: string;
  keys: any;
  setActiveScreen: any;
  currencySelectorProps?: Omit<CurrencySelectorProps, 'localeDataTranslations'>;
}

const MainScreen: FC<Props> = ({
  language,
  isUserLoggedIn,
  sortedCategories,
  marketplaceUrlPrefix,
  pageName,
  keys,
  setActiveScreen,
}) => {
  const logger = useBi();
  const isTemplatesGalleryEnable = useTemplateGalleryExperiment();
  const enableMarketplaceTemplates = isTemplatesGalleryEnable();
  return (
    <>
      <CategoryLinkAccordion
        accordionTitle={keys.mobileServiceMenuTitle}
        categories={sortedCategories}
        marketplaceUrlPrefix={marketplaceUrlPrefix}
        pageName={pageName}
      />
      {enableMarketplaceTemplates && (
        <>
          <div className={s.linkWrapper} role="listitem">
            <StudioTemplatesLink
              label={keys.studioTemplates}
              marketplaceUrlPrefix={marketplaceUrlPrefix}
              pageName={pageName}
              isMobileView={true}
            />
          </div>
          <div className={s.divider}></div>
          <SellInMarketplaceLinkAccordion
            accordionTitle={keys.sellInMarketplace}
            onClick={() =>
              logger.report(
                marketplaceHeaderClientGenericClick({
                  clickType: 'sell in the marketplace',
                  pageName,
                }),
              )
            }
            items={[
              {
                text: keys.offerServices,
                onClick: () => {
                  logger.report(
                    marketplaceHeaderClientGenericClick({
                      clickType: 'sell in the marketplace - offer services',
                      pageName,
                    }),
                  );
                  window.open(keys.offerServicesUrl, '_blank');
                },
              },
              {
                text: keys.sellStudioTemplates,
                onClick: () => {
                  logger.report(
                    marketplaceHeaderClientGenericClick({
                      clickType:
                        'sell in the marketplace - sell studio templates',
                      pageName,
                    }),
                  );
                  window.open(keys.sellStudioTemplatesUrl, '_blank');
                },
              },
            ]}
            pageName={pageName}
          />
        </>
      )}
      <div className={s.divider}></div>
      {isUserLoggedIn && (
        <>
          <div className={s.linkWrapper} role="listitem">
            <MyProjectsLink
              label={keys.myProjects}
              marketplaceUrlPrefix={marketplaceUrlPrefix}
              pageName={pageName}
            />
          </div>
          <div className={s.divider}></div>
        </>
      )}
      <div className={s.linkWrapper} role="listitem">
        <TextButton
          dataHook={dataHooks.languageSelectorLink}
          skin="dark"
          weight="normal"
          className={s.mobileMenuTextButton}
          suffixIcon={<ChevronRight />}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'click in profile drop down menu',
                click_data: 'change language',
                pageName,
              }),
            );
            setActiveScreen('language');
          }}
        >
          <Box>
            <Box paddingRight="SP1">
              <Languages />
            </Box>
            {getLanguageLabel(language)}
          </Box>
        </TextButton>
      </div>
      <div className={s.divider}></div>
      <div className={s.linkWrapper} role="listitem">
        <TextButton
          dataHook={dataHooks.contactSupportLink}
          as="a"
          weight="normal"
          skin="dark"
          href={contactSupportUrl}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'click in profile drop down menu',
                click_data: 'contact support',
                pageName,
              }),
            );
          }}
        >
          {keys.contactSupport}
        </TextButton>
      </div>
      <div className={s.linkWrapper} role="listitem">
        <TextButton
          dataHook={dataHooks.wixLink}
          as="a"
          weight="normal"
          skin="dark"
          href={wixUrl}
          onClick={() => {
            logger.report(
              marketplaceHeaderClientGenericClick({
                clickType: 'click in profile drop down menu',
                click_data: 'go to wix',
                pageName,
              }),
            );
          }}
        >
          {isUserLoggedIn ? keys.wixSites : keys.wix}
        </TextButton>
      </div>
      {!enableMarketplaceTemplates && (
        <div className={s.linkWrapper} role="listitem">
          <TextButton
            dataHook={dataHooks.joinLink}
            as="a"
            weight="normal"
            skin="dark"
            href={`${marketplaceUrlPrefix}/join`}
            onClick={() => {
              logger.report(
                marketplaceHeaderClientGenericClick({
                  clickType: 'click in profile drop down menu',
                  click_data: 'get hired',
                  pageName,
                }),
              );
            }}
          >
            {keys.join}
          </TextButton>
        </div>
      )}
      <div className={s.mobileMenuStickBottom}>
        <div className={s.topGradient}></div>
        <div className={s.content}>
          <div className={s.divider}></div>
          <div className={s.buttons}>
            {isUserLoggedIn ? (
              <LogoutBtn
                btnSize="large"
                btnSkin="standard"
                btnLogoutLabel={keys.btnLogout}
                pageName={pageName}
              />
            ) : (
              <LoginBtn
                btnLoginLabel={keys.btnLogin}
                btnSkin="standard"
                btnSize="large"
                pageName={pageName}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainScreen;
