import React, { FC } from 'react';
import { Button, ButtonProps } from '@wix/design-system';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '@components/Hooks/useBi';
import { dataHooks, loginUrl } from '@components/Header/utils/constants';
import s from '../LoginArea.module.scss';

interface LoginBtnProps {
  btnLoginLabel: string;
  btnPriority?: ButtonProps['priority'];
  pageName: string;
  btnSkin?: ButtonProps['skin'];
  btnSize?: ButtonProps['size'];
}

const LoginBtn: FC<LoginBtnProps> = ({
  btnLoginLabel,
  btnPriority = 'secondary',
  pageName,
  btnSkin = 'dark',
  btnSize = 'small',
}) => {
  const logger = useBi();
  return (
    <Button
      dataHook={dataHooks.loginBtn}
      aria-label={btnLoginLabel}
      skin={btnSkin}
      priority={btnPriority}
      size={btnSize}
      onClick={() => {
        logger.report(
          marketplaceHeaderClientGenericClick({
            clickType: 'Log In',
            pageName,
          }),
        );
        window.open(`${loginUrl(window.location.href)}`, '_self');
      }}
      className={
        btnSkin === 'dark'
          ? btnPriority === 'secondary'
            ? s.btnDarkSecondary
            : s.btnDarkPrimary
          : ''
      }
    >
      {btnLoginLabel}
    </Button>
  );
};

export default LoginBtn;
