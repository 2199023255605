import React, { FC } from 'react';
import { TextButton } from '@wix/design-system';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '../../../Hooks/useBi';
import { myProjectsNewPath, dataHooks } from '../../utils/constants';

interface MyProjectsLinkProps {
  label: string;
  marketplaceUrlPrefix: string;
  pageName: string;
}

const MyProjectsLink: FC<MyProjectsLinkProps> = ({
  label,
  marketplaceUrlPrefix,
  pageName,
}) => {
  const logger = useBi();

  return (
    <>
      <TextButton
        as="a"
        dataHook={dataHooks.myProjectsLink}
        skin="dark"
        size="medium"
        weight="normal"
        onClick={() => {
          logger.report(
            marketplaceHeaderClientGenericClick({
              clickType: 'my-projects',
              pageName,
            }),
          );
        }}
        href={`${marketplaceUrlPrefix}${myProjectsNewPath}`}
      >
        {label}
      </TextButton>
    </>
  );
};

export default MyProjectsLink;
