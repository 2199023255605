import { v4 as uuid } from 'uuid';

const SESSION_KEY = 'marketplaceBiSessionId';

export function sessionIdStorage() {
  let sessionId = getItem() || undefined;
  if (!sessionId) {
    setItem();
    sessionId = getItem() || undefined;
  }
  return sessionId;
}

export function setItem() {
  const value = uuid();
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(SESSION_KEY, value);
  }
}
export function getItem() {
  let sessionId;
  if (typeof window !== 'undefined') {
    sessionId = window.sessionStorage.getItem(SESSION_KEY);
  }
  return sessionId;
}
